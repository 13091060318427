/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyonrails.org/world/2024"
  }, "Rails World 2024")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://railsconf.org/"
  }, "Rails Conf 2024")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://rubyonrails.org/foundation"
  }, "Rails Foundation")), "\n"), "\n", React.createElement(_components.p, null, "Rails World 분할 사건"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.platformer.news/-what-really-happened-at-basecamp/"
  }, "Basecamp에서 일어난 일(영문)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://world.hey.com/dhh/no-railsconf-faa7935e"
  }, "RailsConf에 참여하지 않겠다는 DHH의 글(영문)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://x.com/dhh/status/1643994620635000839"
  }, "Rails World 런칭 공지 트윗")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://world.hey.com/dhh/the-last-railsconf-c6188593"
  }, "2025년에 마지막이 될 RailsConf에 보내는 DHH의 작별 인사(영문)")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
